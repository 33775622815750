import { graphql } from "gatsby";
import * as React from "react"
import { useTranslation } from "react-i18next"
import BigButton from "../../components/BigButton";
import DefaultLayout from "../../layout";
import {Book, HelpCircle} from "lucide-react"
import { Discord } from '@icons-pack/react-simple-icons'

import * as styles from "../index.module.scss"

const DiscordPage = ({data}) => {
  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("discord")}>
      <div className={styles.layoutBox}>
        <h1>{t("discord")}</h1>
        
        <div className={styles.flexList}>
          <BigButton external={true} to={data.site.siteMetadata.discordInvite} icon={Discord} text={t("discordJoin")} />
          <BigButton external={false} to={"/discord/rules"} icon={Book} text={t("rules")} />
          <BigButton external={false} to={"/discord/faq"} icon={HelpCircle} text={t("faq")} />
        </div>

      </div>
    </DefaultLayout>
  )
}

export default DiscordPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        discordInvite
      }
    }
  }
`;
